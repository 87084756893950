
import React from 'react'
import Particles from 'react-particles-js'
import styled from 'styled-components'

const HeroWatermark = require('../../dist/images/AuxidusA.svg');

export default ({ children }) => {
    const ParticleTypes = [
        { "particles":{"number":{"value":350,"density":{"enable":true,"value_area":25000}},"color":{"value":"#0AA7CF"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":3},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.1,"random":false,"anim":{"enable":true,"speed":0.25,"opacity_min":0,"sync":false}},"size":{"value":15,"random":true,"anim":{"enable":false,"speed":15,"size_min":11.988011988011989,"sync":false}},"line_linked":{"enable":false,"distance":250,"color":"#103941","opacity":0.75,"width":5},"move":{"enable":true,"speed":2,"direction":"none","random":true,"straight":false,"out_mode":"bounce","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"window","events":{"onhover":{"enable":false,"mode":"grab"},"onclick":{"enable":false,"mode":"remove"},"resize":true},"modes":{"grab":{"distance":150,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true },
        { "particles": { "number": { "value": 50, "density": { "enable": true, "value_area": 1000 } }, "color": { "value": "#bebebe" }, "shape": { "type": "circle", "stroke": { "width": 0, "color": "#000000" }, "polygon": { "nb_sides": 5 }, "image": { "src": "img/github.svg", "width": 100, "height": 100 } }, "opacity": { "value": 0.5, "random": false, "anim": { "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false } }, "size": { "value": 4, "random": false, "anim": { "enable": false, "speed": 40, "size_min": 0.1, "sync": false } }, "line_linked": { "enable": true, "distance": 300, "color": "#bebebe", "opacity": 0.4, "width": 1 }, "move": { "enable": true, "speed": 1.5, "direction": "none", "random": false, "straight": false, "out_mode": "out", "bounce": false, "attract": { "enable": false, "rotateX": 600, "rotateY": 1200 } } }, "interactivity": { "detect_on": "canvas", "events": { "onhover": { "enable": true, "mode": "grab" }, "onclick": { "enable": false, "mode": "push" }, "resize": true }, "modes": { "grab": { "distance": 200, "line_linked": { "opacity": 1 } }, "bubble": { "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3 }, "repulse": { "distance": 200, "duration": 0.4 }, "push": { "particles_nb": 4 }, "remove": { "particles_nb": 2 } } }, "retina_detect": true }
    ];

    return <ParticlesContainer>
        { ParticleTypes.map(particleStyle => <StyledParticles params={particleStyle} />) }
        <Watermark />
        {children}
        </ParticlesContainer>
}

const ParticlesContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const StyledParticles = styled(Particles)`
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;  
  width: 100%; height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.6;  
`;
const Watermark = styled.div`
  height: 70%;
  top: 15%;
  left: 0;
  right: 0;
  z-index: -1;
  width: auto;
  background: url(${HeroWatermark}) no-repeat center center; 
  background-size: contain;
  opacity: 0.1;
  position: absolute;
`;