
import React, { useState } from 'react'
import { Card, Container, Box } from '@material-ui/core'
import { Divider, Typography } from '.'
import { Colors } from '../constants'
import styled, { keyframes, css } from 'styled-components'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default ({ title, body, footer, callback }) => {
    const [isCardElevated, setIsCardElevated] = useState(false);
    return (
        <StyledCard onClick={() => callback()} onMouseEnter={() => setIsCardElevated(true)} onMouseLeave={() => setIsCardElevated(false)} elevation={isCardElevated ? 6 : 2}>
            <CardHeader>
                <Typography TType={'header'} fontWeight={'bold'} variant={'h5'} body={title} />
            </CardHeader>
            <CardBody>
                <Typography classes={''} variant='body2' TType="primary" component='p' body={body} />
            </CardBody>
            <CardFooter>   
                <CardFooterContainer isCardElevated={isCardElevated}>
                    <div style={{textAlign: 'left'}}>
                        <Typography variant={'button'} TType={'footer'} body={footer} />
                    </div>               
                    <div style={{textAlign: 'right'}}>
                        <Typography variant={'button'} TType={'footer'} body={<ArrowForwardIosIcon />} />
                    </div>         
                </CardFooterContainer>       
            </CardFooter>
        </StyledCard>
    )
}


const ShakeKeyframes = keyframes`
    0%, 100% {transform: translateX(0px);} 
    50% {transform: translateX(-15px);} 

    0%, 100% {-webkit-transform: translateX(0px);} 
    50% {-webkit-transform: translateX(-15px);} 
`;
const animationMixin = props => css` ${ShakeKeyframes} 1.25s both infinite `;
const StyledBox = styled(Box)`
    text-align: center;
`;
const StyledCard = styled(Card)`
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom: 2px solid ${Colors.auxidusBlue};
        cursor: pointer;
    }
`;
const CardHeader = styled(StyledBox)`
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    height: 72px;
`;
const CardBody = styled(StyledBox)`
    padding: 5% 10%;
    height: 235px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
`;
const CardFooter = styled(CardHeader)`
    height: 72px;
`;
const CardFooterContainer = styled(Container)`
    display: grid;
    width: 100%;
    grid-template-columns: 70% 30%;

    div {
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;

        span {
            color: ${props => props.isCardElevated ? Colors.auxidusBlue : 'default' };
            svg {
                animation: ${props => props.isCardElevated ? animationMixin : 'none'};
            }
        }
    }
    
`;