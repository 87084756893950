
import React from 'react'
import Particles from 'react-particles-js'
import styled from 'styled-components'

export default (props) => {
    console.log(props.particleStyle)

    return (
        <ParticleContainer>
            <StyledParticles
                params={ props.particleStyle == 'twilight' 
                    ? { "particles":{"number":{"value":350,"density":{"enable":true,"value_area":25000}},"color":{"value":"#0AA7CF"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":3},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.1,"random":false,"anim":{"enable":true,"speed":0.25,"opacity_min":0,"sync":false}},"size":{"value":15,"random":true,"anim":{"enable":false,"speed":15,"size_min":11.988011988011989,"sync":false}},"line_linked":{"enable":false,"distance":250,"color":"#103941","opacity":0.75,"width":5},"move":{"enable":true,"speed":2,"direction":"none","random":true,"straight":false,"out_mode":"bounce","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"window","events":{"onhover":{"enable":false,"mode":"grab"},"onclick":{"enable":false,"mode":"remove"},"resize":true},"modes":{"grab":{"distance":150,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true }
                    : { "particles":{"number":{"value":15,"density":{"enable":true,"value_area":500}},  "color":{"value":"#88DAD7"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.35,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},"size":{"value":5,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},"line_linked":{"enable":true,"distance":250,"color":"#00102f","opacity":0.75,"width":3},"move":{"enable":true,"speed":1.5,"direction":"none","random":false,"straight":false,"out_mode":"bounce","bounce":false,"attract":{"enable":false,"rotateX":250,"rotateY":250}}},"interactivity":{"detect_on":"window","events":{"onhover":{"enable":true,"mode":"grab"},"onclick":{"enable":false,"mode":"push"},"resize":true},"modes":{"grab":{"distance":300,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true }            
                }
            />
        </ParticleContainer>
    )
}



const ParticleContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
const StyledParticles = styled(Particles)`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat; 
    background-size: cover; 
    background-position: 50% 50%;
`;