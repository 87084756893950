
import React from 'react'
import { Divider } from '@material-ui/core'
import { Colors } from '../constants'
import styled from 'styled-components'

export default () => {
    return (
        <StyledDivider />
    )
}

const StyledDivider = styled(Divider)`
    // background-color: ${Colors.backgroundLightGray};
`