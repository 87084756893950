
import React, { useState, useMemo, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Hero from './_hero'
import Layout from '../../components/layout'
import { Card } from '../../components'
import { Grid, Container, Typography, Paper } from '@material-ui/core'
import Fade from 'react-reveal/Fade';
import { Colors } from '../../constants'
import Carousel from 'react-material-ui-carousel'
import Ticker from 'react-ticker'
import { navigate } from 'gatsby'

import PageVisibility from 'react-page-visibility'

export default () => {
    const [windowWidth, setWindowWidth] = useState(null)
    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    useEffect(() => { setWindowWidth(window?.innerWidth) }, []);
    useMemo(() => {
        const updateWidth = () => {
            setWindowWidth(window.innerWidth)
        }
        try {
            window.addEventListener('resize', updateWidth)
        } catch {
        }
    }, []);

    const services = [
        { 
          name: 'Enterprise Web Applications', 
          loc: 'webapps',
          description: 
                <div>
                    Satisfy any business requirement with custom engineered software, built with security, scalability, and user experience in mind.
                    <ul style={{ paddingLeft: '35px', paddingTop: '15px' }}>
                        <li>Reduce Operations Overhead</li>
                        <li>Easily Integrated With Existing Workflow</li>
                    </ul>
                </div>
        }, 
        { 
          name: 'Website Development', 
          loc: 'websites',
          description: 
            <div>
                Convey your message clearly and effectively with a pixel-perfect conversion of design to end product.
                <ul style={{ paddingLeft: '35px', paddingTop: '15px' }}>
                    <li>Portabile &amp; Responsive Designs</li>
                    <li>User Analytics Support</li>
                    <li>Search Engine Optimization</li>
                </ul>
            </div>
        }, 
        { 
          name: 'Consultations', 
          loc: 'consultations',
          description: 
            <div>
                Overdrive your team's IT infrastructure through the implementation of modern tools and techniques.
                <ul style={{ paddingLeft: '35px', paddingTop: '15px' }}>
                    <li>Design or Revamp Your Tech Stack</li>
                    <li>Manage Application Lifecycle</li>
                    <li>General Technology Consultations</li>
                </ul>
            </div>
        }
    ];

    return (
        windowWidth ? 
            <Layout padTop={true} title={'Auxidus Technologies'} description={"We're a team of forward-thinking developers providing new-age technologies to the present-day business. Find out how we can optimize your team's productivity with a free consultation."}>
            
                <ParticlesWrapper style={{paddingTop: '25px'}}>
                    <Hero>
                        <Container>
                        <HeroText variant={'h3'} type='primary'>
                            Modernizing businesses through tailored software solutions
                        </HeroText>
                        
                        <HeroSeparator />
                        <HeroText variant={'button'} style={{marginTop: '45px'}} type='secondary'>
                            We're a team of forward-thinking developers providing new-age technologies to the present-day business.
                        </HeroText>
                        </Container>
                    </Hero>
                </ParticlesWrapper>
                <CardsWrapper>
                    <Separater />
                    <Fade bottom>
                    <Container>
                        <div style={{width: '100%', height: 'calc(5vh + 550px)', position: 'relative'}}>
                            <StyledGrid container spacing={2}>
                                {
                                    windowWidth >= 768
                                    ?   services.map(service => {
                                            return (
                                                <Grid key={service.id} item xs={12} sm={4} md={4} xl={4}>
                                                    
                                                        <Card   
                                                            title={service.name} 
                                                            body={service.description}  
                                                            footer={"Learn More"}
                                                            callback={() => service.loc == 'consultations' 
                                                                ? navigate(`/company/contact`) 
                                                                : navigate(`/services/${service.loc}`)
                                                            }
                                                        />
                                                    
                                                </Grid> 
                                            )
                                        })
                                    :   <Grid item xs={12}>
                                            
                                                <Carousel navButtonsAlwaysInvisible={true} animation={'slide'} interval={5000} activeIndicatorProps={{style: {color: Colors.auxidusBlue}}}>
                                                    {
                                                        services.map(service => {
                                                            return (
                                                                <Card 
                                                                    title={service.name} 
                                                                    body={service.description} 
                                                                    footer={"Learn More"}
                                                                    callback={() => service.loc == 'consultations' ? navigate(`/company/contact`) : navigate(`/services/${service.loc}`)}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            
                                        </Grid>
                                }
                                
                                    <Grid item xs={12}>
                                        <CarouselWrapper elevation={1}>
                                            <Typography variant={'button'} style={{color: 'rgba(10, 167, 207, 0.75)', fontWeight: 'bold'}}>OUR FOUNDERS HAVE WORKED WITH</Typography>
                                            
                                            <MarqueeWrapper>
                                                    
                                                <MarqueeInner>
                                                    
                                                    <PageVisibility onChange={handleVisibilityChange}>
                                                        {
                                                            pageIsVisible && <Ticker mode={'smooth'} speed={5}>
                                                                {
                                                                    () => 
                                                                        
                                                                        ["Akamai Technologies", "Pinnacle Web Development", "iHerb", "RMS Life Safety", "Taft Electric Company", "Mount Shasta Ranch B&B",
                                                                        "US Marine Corps", "Main Electric Supply Co."
                                                                        ].map((item, i) => (
                                                                            <><MarqueeItem variant="button" index={i}>&nbsp;{item}&nbsp;</MarqueeItem></>
                                                                        )
                                                                    )                                                                    
                                                                }
                                                            </Ticker>
                                                        }  
                                                    </PageVisibility>
                                                    
                                                </MarqueeInner>
                                                                                        
                                            </MarqueeWrapper>
                                            
                                        </CarouselWrapper>
                                    </Grid>
                                
                            </StyledGrid>
                        </div>                    
                    </Container>
                    </Fade>
                </CardsWrapper>
            
            </Layout>
        : null
    )
}

const Separater = styled.div`
    background-color: #fefefe;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='12' viewBox='0 0 40 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z' fill='%23666666' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: 10px;
    width: 100%;
`;
const MarqueeWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 42px;
    margin-top: 5px;
`
const MarqueeInner = styled.div`
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
`
const MarqueeItem = styled(Typography)`
    text-transform: inherit;
    font-weight: bold;
    color: ${props => props.index % 2 == 0 ? "rgba(10, 167, 207, 0.35)": "#D2D6DC"};
    font-size: 30px;
    cursor: default;
`
const CarouselWrapper = styled(Paper)`
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    position: relative;
`
const HeroSeparator = styled.div`
    margin-top: 25px;
    margin-left: 0;
    margin-right: auto;
    display: block;
    border: none;
    height: 3px;
    width: 7.5%;
    background: ${Colors.auxidusBlue};
`;
const HeroText = styled(Typography)`
    text-align: left;
    display: block;
    font-weight: ${props => props.type === 'primary' ? 'bold' : 'initial'};
    text-transform: ${props => props.type === 'primary' ? 'uppercase' : 'initial'};
    color: ${props => props.type === 'primary' ? Colors.backgroundBlueLighter : Colors.backgroundBlueLighter};
    opacity: ${props => props.type === 'primary' ? 1 : 0.8};

    font-size: ${props => props.type === 'primary' ? 'calc(2em + 1vw)' : 'calc(0.25em + 0.75vw)'};
    @media screen and (max-width: 75em) {
        font-size: ${props => props.type === 'primary' ? 'calc(1.5em + 1vw)' : 'calc(0.75em + 0.5vw)'};
    };
         
`;
const ParticlesWrapper = styled.div`
    height: calc(27.5vmax + 175px);
    width: 100%;
    position: relative;
`;
const CardsWrapper = styled.div`
    background: #f3f3f3;
    position: relative;
    width: 100%;
`;
const StyledGrid = styled(Grid)`
  position: absolute;
  top: -5vmax;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;