
import React from 'react'
import { Typography } from '@material-ui/core'
import { Colors } from '../constants'
import styled from 'styled-components'

export default (props) => {

    return (
        <StyledTypography classes={props.classes} fontWeight={props.fontWeight} upperCase={props.upperCase} variant={props.variant} TType={props.TType}>
            {props.body}
        </StyledTypography>
    )
}

const StyledTypography = styled(Typography)`
    color: ${props => props.TType === 'main' ? `${Colors.textWhite}`
                    : props.TType === 'primary' ? `${Colors.textPrimaryGray}`
                    : props.TType === 'footer' ? `${Colors.textPrimaryGray}`
                    : props.TType === 'header' ? `${Colors.auxidusBlue}`
                    
                    : 'black'
    };
    text-transform: ${props => props.upperCase ? 'uppercase' : ' '};
    font-weight: ${props => props.TType === 'header' ? 'bold' : 'normal'}
    font-size: ${props => props.TType === 'header' ? 'calc(14px + 2 * ((100vw - 185px) / 640))' : 'deafult'};
`